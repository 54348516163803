<template>
  <v-container class="font-italic white--text pb-15 workmain"> 
    <LoginOut/>  
    <v-card   > 
    <div color="cyan" dark flat class="bg"   >  
           <div style="flex: 2; z-index: 2">
              <div class="d-flex justify-center" style="position: relative;">
                <div >
                  <h1 class="mt-3" style="font-size: var(--big-size);color: white;  text-shadow: 2px 2px 2px gray;">ChatPT</h1>
                  <v-btn  class="mt-1" color="blue" style="color:white" @click="$router.push('/chatpt')"   >
                    <h2>운동상담</h2>  
                  </v-btn>
                  <h3 class="smallText mt-5 mb-3" style="color: white;  text-shadow: 2px 2px 2px gray;">
                    AI트레이너 '챗PT'가 <br>맞춤형 운동을 추천해드립니다.
                  </h3>
                </div>
                <!-- <img class="boygirlS ml-2" :src="`${require('@/assets/boyGirl.png')}`" style="z-index: 1" /> -->
                <img class="jumboRight people" :src="`${require('@/assets/boyGirl.png')}`" style="z-index: 1" />
              </div>
              <!-- <h3   style="color: white;  text-shadow: 2px 2px 2px gray;">맞춤형 운동을 추천해드립니다.</h3> -->
                 
             </div>
        </div>
        </v-card>
    <!-- 메뉴 -->  
    <div class="mt-5" >
      <div class="d-flex  ">
        <img :src="`${require('@/assets/character/boy3-s.png')}`" width="50px" style="transform: scaleX(-1);" />
        <span class="mt-2 ml-2 text-left italic" style="color: white;font-weight: 900;;z-index: 1">
          <h1 style="text-shadow: 2px 2px 2px gray;">
            WorkOut
          </h1>
          <h5>근육부위별 체력운동!</h5>
        </span> 
      </div>
      
      <div class="pa-3 d-flex justify-space-between itemRow"  >
          <v-card class="mr-2 bg" v-for="(n, i) in workoutType" :key="i"  @click="routLink2(n,'workout')">
            <v-img :src="`${require('@/assets/muscle/'+n+'.png')}`" width="70" height="150" class="ma-1">  </v-img>
            <h4 style="color: white;">{{ n }}</h4>
        </v-card>
     </div>
  </div>
    <!-- 새로운운동 타이틀 -->
    <div class="d-flex mt-5 mb-2 justify-space-between">
      <div class="d-flex">
        <img :src="`${require('@/assets/character/boy4-s.png')}`" width="55px" style="z-index: 1" />
        <span class="mt-2 ml-2 text-left italic" style="color: white;font-weight: 900;;z-index: 1">
          <h1 style="text-shadow: 2px 2px 2px gray;">
            Challenge
          </h1>
          <h5>운동기록에 도전해보세요!</h5>
        </span>  
      </div>
      <v-btn class="mt-5" small rounded color="blue"  @click="$router.push('/playmore')" > <h3 style="color: white;">더보기</h3>  </v-btn>
    </div>
    <!-- 새로운운동 아이템들 -->
    <v-row class="itemRow" v-if="itemsTF" >
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-skeleton-loader max-height="150" type="card" />
      </v-col>
    </v-row>
    <v-row v-else >
      <!-- <v-col v-for="(n, i) in items.filter( (n) => n.type === 'workout' && dateCompare(n.endDate) )" -->
      <v-col v-for="(n, i) in items" :key="i" cols="12" sm="6" md="4" lg="3" >
      <!-- <v-col v-for="(n, i) in items.filter( (n) => dateCompare(n.endDate) )" :key="i" cols="12" sm="6" md="4" lg="3" > -->
        <v-card :loading="loading" class="mx-auto" style="background: #08f0ff" @click="routLink(n,'play')" >
          <v-img max-height="150" :src="`${require('@/assets/bg.png')}`" > 
            <div class="ma-3 pb-5 rounded-lg white--text menuBox workout">
              <v-card-title><h3>{{ n.title }}</h3></v-card-title>
              <v-card-text>
                <v-row class="mx-0">
                  <v-rating :value="n.rating" color="amber" dense half-increments readonly size="14" ></v-rating>
                  <v-spacer></v-spacer>
                  <div class="grey--text ms-4">
                    <span style="color: var(--second-color)">{{ n.team }}</span>
                  </div>
                </v-row>
                <div class="mt-5 text-subtitle-1">
                  {{ n.content }}
                </div>
              </v-card-text>
            </div>
          </v-img>
          <v-card-actions >
            <v-icon >mdi-timetable</v-icon> {{ dateCompare(n.endDate)?n.endDate:'챌린지기간 만료' }}
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script> 
import LoginOut from "./LoginOut.vue";
export default {
    name: "HomeView",
    components: { LoginOut },
    data() {
        return {
            fab: false,
            today: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                .toISOString()
                .substring(0, 10),
            dialogLogin: false,
            loading: false,
            gameItemsTF: true, 
            gameItems: [],
            showPT: false,
            workoutType: ["가슴", "어깨", "이두","복근","삼두", "등",  "다리",  "힙", "전신"],
            workoutTool: ["맨몸", "덤벨", "바벨", "케이블", "기구", "머신"]
        };
    },
    created() {
      this.getData()
        if (this.$store.state.workout.length === 0) {
          this.getAirtable()
            // Promise.all([this.getData(), this.getAirtable()]);
        }
        //   if (this.$store.state.workoutData.length === 0) {
        //     this.$store.dispatch('fetchData');
        // }
    },
    computed: {
        items() {
            return this.$store.state.workRooms;
        },
        itemsTF() {
            return this.$store.state.workRooms.length === 0;
        },
    },
    methods: {
      getData(){
      Promise.all([this.getWorkRoom()])
    },
    async getWorkRoom() {
          try {
            const sn = await this.$firebase.firestore().collection("workout").get();
            const workRooms = sn.docs.map((e) => e.data());
             workRooms.sort((a, b) => new Date(b.startDate) - new Date(a.startDate));

            this.$store.commit("setWorkRooms", workRooms);
          } catch (e) {
            console.error(e);
          }
        },
      async getAirtable() {
      try {
        const API_KEY = process.env.VUE_APP_AIRTABLE;
        const airtableUrl = `https://api.airtable.com/v0/app5liDmy2ljtESsd/workout`;
        const headers = {
          Authorization: `Bearer ${API_KEY}`
        };
      
        const workItems = [];
        let offset;
        //에어테이블에서 한번에 가져올 수 있는 데이터양은 100개이므로 반복문으로 페이지네이션 작업필요
        while (true) {
          const response = await fetch(`${airtableUrl}?offset=${offset || ''}`, { headers });
          const json = await response.json();
          const records = json.records.map(record => ({
            id: record.id,
            muscle: record.fields.muscle,
            tools: record.fields.tools,
            title: record.fields.title,
            desc: record.fields.desc,
            short: record.fields.short,
            png: record.fields.png,
            gif: record.fields.gif 
            //에어테이블의 첨부 이미지를 가져올때
            // png: record.fields.png ? record.fields.png[0].url : null,
            // gif: record.fields.gif ? record.fields.gif[0].url : null,
          }));
          workItems.push(...records);
          offset = json.offset;
          console.log(workItems)
          if (!offset) {
            break;
          }
        }
        console.log('hihihih',workItems)
      this.$store.commit("setWorkout", workItems);
    } catch (error) {
      console.error(error);
    }
  },
        dateCompare(day) {
            if (day) {
              const date1 = new Date(this.today);
              const date2 = new Date(day); 
                return date1 <= date2;
            }
            else {
                return true;
            }
        },
        routLink(n, a) {
            this.$router.push({
                name: a,
                params: { id: n.id },
                // params: { id: n.id, data: n },
            });
        },
        routLink2(n, a) {
          // this.getAirtable()
            this.$router.push({
                name: a,
                params: { name: n, workout: this.workItems },
            });
        },
        logout() {
            this.$firebase.auth().signOut();
            location.reload(true);
        },
    },
};
</script>
<style scoped>
.workmain{
  height: 100%;
    /* background-image: url(@/assets/bg.png); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  letter-spacing: 0.5px;
}
.workmain::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  filter: brightness(0.9);  
  background-color: #3373bc; 
    /* background-image: url(@/assets/bg.png); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  letter-spacing: 0.5px;
}

.menuBox {
  height: 100%;
  cursor: pointer;
  transition: all 0.5s;
  backdrop-filter: blur(5px);
  border-radius: 20px;
  background: rgba(255, 255, 0, 0.5);
  box-shadow: 0 25px 45px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.menuBox:hover {
  background: rgba(255, 255, 255, 0.3); 
}
.menuBox.workout {
  background: rgba(0, 0, 0, 0.3);
  border: none;
  border-right: none;
  border-bottom: none;
  overflow: hidden;
}
 
.menuBox.workout:hover {
  background: rgba(255, 255, 0, 0.5);
}
.title {
  /* font-size: 100px; */
  text-shadow: 2px 2px 2px gray;
}
.itemRow {
  flex-wrap: nowrap;
  overflow-x: auto;
}
.menuBox:hover h3 {
  transition: all 0.2s;
  transform: scale(1.1);
}
.bg{
  background-image: url(@/assets/bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  position: relative;
} 
.logo{
  width: 200px;
  background-image: url(../assets/character/boyGirl-s.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.smallText {
  text-transform: uppercase;
} 
.boygirlS{
  /* position: absolute;
  right: 50px;
  top: -40px; */
  width: 180px;
}
.people {
  position: absolute;
  right: -40px;
  top: -30px;
  width: 325px;
}
.jumboHeight{
  height: 200px;
}
@media (max-width: 900px) {
  .jumboRight {
    display: none;
  }
}
@media (min-width: 900px) {
  .boygirlS {
    display: none;
  }
}
/* .login {
  margin-top: 20px;
} */
@media (max-width: 500px) {
  .smallText {
    font-size:medium;
  }
  .menuBox {
    border-radius: 10px;
  }
  /* .login {
    margin-top: 2px;
  } */
}
</style>
