<template>
  <div class="font-italic ">
    <div class="d-flex login " style="height:45px"> 
      <v-spacer></v-spacer>
        <v-btn small rounded color="blue" style="transform: translateY(10px);z-index: 5" @click="dialogLogin = true" v-if="!$store.state.fireUser"  >
          <h3 style="color: white;">LOGIN</h3> 
          <!-- var(--logout-color) -->
        </v-btn>
        <v-btn small rounded color="blue" style="transform: translate(-10px,10px);z-index: 5" @click="logout" v-else >
              <h3  style="color: white;">LOGOUT</h3>
        </v-btn>         
        <v-progress-circular indeterminate color="var(--main-color)"  v-if="$store.state.fireUser&&!$store.state.userData"  />
        <v-speed-dial v-model="fab"    direction="right"  v-if="$store.state.fireUser&&$store.state.userData"  >
            <template v-slot:activator> 
              <v-btn v-model="fab" small color="transparent" dark fab @click="navigateToDashboard" >
                <Avataaars :width="50" :height="50" :avatarOptions="$store.state.userData.options"  />
              </v-btn>
            </template> 
        </v-speed-dial>
    </div> 
    <v-dialog v-model="dialogLogin" max-width="500px">
      <DialogLogin @close="dialogLogin = false" />
    </v-dialog>
  </div>
</template>

<script>
import DialogLogin from "@/components/DialogLogin.vue";
import Avataaars from "vue-avataaars"; 
export default {
  name: "LoginOut",
  components: { Avataaars, DialogLogin },
  data() {
    return {
      fab:false,
      today: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substring(0, 10),
      dialogLogin: false,
      loading: false, 
    };
  },
  created() { 
    // if(this.$store.state.workRooms.length === 0){
      // this.getData()
    // }
    if(this.$store.state.workout.length === 0){
      this.getAirtable()
    }
  
  }, 
//   watch: {
//   '$store.state.workRooms': {
//     immediate: true,
//     handler(newWorkRooms) {
//       if (newWorkRooms.length === 0) {
//         this.getData();
//       }
//     }
//   }
// },
  methods: {
    // getData(){
    //   Promise.all([this.getWorkRoom()])
    // },
    // async getWorkRoom() {
    //       try {
    //         const sn = await this.$firebase.firestore().collection("workout").get();
    //         const workRooms = sn.docs.map((e) => e.data());
    //         console.log(workRooms)
    //             workRooms.sort((a, b) => new Date(b.startDate) - new Date(a.startDate));

    //         this.$store.commit("setWorkRooms", workRooms);
    //       } catch (e) {
    //         console.error(e);
    //       }
    //     },

    async getAirtable() {
      try {
        const API_KEY = process.env.VUE_APP_AIRTABLE;
        const airtableUrl = `https://api.airtable.com/v0/app5liDmy2ljtESsd/workout`;
        const headers = {
          Authorization: `Bearer ${API_KEY}`
        };
      
        const workItems = [];
        let offset;

        while (true) {
          const response = await fetch(`${airtableUrl}?offset=${offset || ''}`, { headers });
          const json = await response.json();
          const records = json.records.map(record => ({
            id: record.id,
            muscle: record.fields.muscle,
            tools: record.fields.tools,
            title: record.fields.title,
            desc: record.fields.desc,
            short: record.fields.short,
            png: record.fields.png ? record.fields.png[0].url : null,
            gif: record.fields.gif ? record.fields.gif[0].url : null,
          }));
          workItems.push(...records);
          offset = json.offset;

          if (!offset) {
            break;
          }
        }
      this.$store.commit("setWorkout", workItems);
    } catch (error) {
      console.error(error);
    }
  },
    dateCompare(day) {
      if (day) {
        const date1 = new Date(this.today);
        const date2 = new Date(day);
        return date1 <= date2;
      } else {
        return true;
      }
    }, 
    logout() {
      if (this.$route.path !== '/') {
      this.$router.push('/');
    }

      this.$firebase.auth().signOut();
      this.$store.commit("setUserData", null);
      this.$store.commit("setfireUser", null);
      // this.getData()
      // location.reload(true) 
    },
    navigateToDashboard() {
    if (this.$route.path !== '/dashboard') {
      this.$router.push('/dashboard');
    }
  }
  },
};
</script>
<style scoped> 
</style>
