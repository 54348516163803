<template>
  <v-container class="font-italic white--text pb-15">
    <!-- 로그인 -->
    <div class="d-flex login pr-5" style="height:45px"> 
        <v-progress-circular indeterminate color="var(--main-color)"  v-if="$store.state.fireUser&&!$store.state.userData"  />
        <v-speed-dial v-model="fab"    direction="right"  v-if="$store.state.fireUser&&$store.state.userData"  >
            <template v-slot:activator> 
              <v-btn v-model="fab" small color="transparent" dark fab style="transform: translateX(10px);" >
                <Avataaars :width="50" :height="50" :avatarOptions="$store.state.userData.options"  />
              </v-btn>
            </template> 
            <!-- <v-btn rounded dark small @click="logout" color="var(--main-color)" style="color:black;"  > PROFILE </v-btn> -->
        </v-speed-dial>
        <v-btn small rounded color="var(--main-color)" style="transform: translateY(10px);" @click="dialogLogin = true" v-if="!$store.state.fireUser"  >
          <h3>LOGIN</h3>
        </v-btn>
        <v-btn small rounded color="var(--logout-color)" style="transform: translate(20px, 10px);color:white" @click="logout" v-else >
              <h3>LOGOUT</h3>
        </v-btn> 
        <v-spacer></v-spacer> 
          <v-btn text dark x-small to="/" color="var(--main-color)" style=" margin:0;transform: translate(20px, 15px);">
            <span class="mdi mdi-36px mdi-home"></span> </v-btn>
    </div> 
    <v-dialog v-model="dialogLogin" max-width="500px">
      <DialogLogin @close="dialogLogin = false" />
    </v-dialog>
    <!-- 점보 -->
    <div class="rounded-lg d-flex jumbo white--text" >
      <div class="pa-5" style="flex: 2; z-index: 2">
        <div class="justify-center">
      <v-img :src="bgImg" width="80" class="mx-auto my-0 pa-0"  />
          <h1>Body Workout Game </h1>
        </div>
      </div>
    </div>
    <!-- 새로운운동 타이틀 -->
    <div class="d-flex justify-space-between">
      <h2 class="text-left mt-5 title" style="color: #d7e357">
        <v-icon color="#d7e357" class="mb-1">mdi-gamepad-variant-outline</v-icon>
        New Game
      </h2>
      <v-btn class="mt-5" rounded small color="var(--main-color)" disabled @click="$router.push('/creategame')">CREATE</v-btn>
    </div>
    <!-- 새로운운동 아이템들 -->
    <v-row class="itemRow" v-if="gameItemsTF" >
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-skeleton-loader max-height="150" type="card" />
      </v-col>
    </v-row>
    <v-row class="itemRow" v-else >
      <!-- <v-col v-for="(n, i) in items.filter( (n) => n.type === 'workout' && dateCompare(n.endDate) )" -->
      <v-col v-for="(n, i) in gameItems.filter( (n) => dateCompare(n.endDate) )"
        :key="i" cols="12" sm="6" md="4" lg="3" >
        <v-card :loading="loading" class="mx-auto" style="background: #d7e357" @click="routLink(n,'game')" >
          <v-img max-height="150" :src="`${require('@/assets/fitness/card3.png')}`" > 
            <div class="ma-3 pb-5 rounded-lg white--text menuBox workout">
              <v-card-title><h3>{{ n.title }}</h3></v-card-title>
              <v-card-text>
                <v-row class="mx-0">
                  <v-rating :value="n.rating" color="amber" dense half-increments readonly size="14" ></v-rating>
                  <v-spacer></v-spacer>
                  <div class="grey--text ms-4">
                    <span style="color: var(--second-color)">{{ n.team }}</span>
                  </div>
                </v-row>
                <div class="mt-5 text-subtitle-1">
                  {{ n.content }}
                </div>
              </v-card-text>
            </div>
          </v-img>
          <v-card-actions >
            <v-icon >mdi-timetable</v-icon> {{ n.endDate }}
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DialogLogin from "@/components/DialogLogin.vue";
import Avataaars from "vue-avataaars";
export default {
  name: "GameMore",
  components: { Avataaars, DialogLogin },
  data() {
    return {
      fab:false,
      today: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substring(0, 10),
      dialogLogin: false,
      loading: false,
      itemsTF:true,
      gameItemsTF:true,
      items: [], 
      gameItems: [],  
      bgImg: require("../assets/logo.png"),
    };
  },
  created() {
    this.getData();
    console.log('hi')
  },
  methods: {
    getData() { 
      this.$firebase.firestore().collection("game").get().then((sn) => {
          this.gameItems = sn.docs.map((e) => e.data());
        }).catch((e) => console.log(e)).finally(()=>{this.gameItemsTF=false});
    },
    dateCompare(day) {
      if (day) {
        const date1 = new Date(this.today);
        const date2 = new Date(day);
        return date1 <= date2;
      } else {
        return true;
      }
    },
    routLink(n,a) {
      this.$router.push({
        name: a,
        params: { id: n.id },
        // params: { id: n.id, data: n },
      });
    },
    logout() {
      this.$firebase.auth().signOut();
      location.reload(true)
    },
  },
};
</script>
<style scoped>
.menuBox {
  height: 100%;
  cursor: pointer;
  transition: all 0.5s;
  backdrop-filter: blur(5px);
  border-radius: 20px;
  background: rgba(255, 255, 0, 0.5);
  box-shadow: 0 25px 45px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.menuBox:hover {
  background: rgba(255, 255, 255, 0.3); 
}
.menuBox.workout {
  background: rgba(0, 0, 0, 0.3);
  border: none;
  border-right: none;
  border-bottom: none;
  overflow: hidden;
}
 
.menuBox.workout:hover {
  background: rgba(255, 255, 0, 0.5);
}
.title {
  text-shadow: 2px 2px 2px gray;
}
.itemRow {
  flex-wrap: wrap;
  /* overflow-x: auto; */
}
.menuBox:hover h3 {
  transition: all 0.2s;
  transform: scale(1.1);
}
.smallText {
  text-transform: uppercase;
}
/* .login {
  margin-top: 20px;
} */
@media (max-width: 500px) {
  .smallText {
    font-size: x-large;
  }
  .menuBox {
    border-radius: 10px;
  }
  /* .login {
    margin-top: 2px;
  } */
}
</style>
