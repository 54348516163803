<template>
  <v-container class="font-italic white--text pb-15">
    <!-- 로그인 -->
    <div class="d-flex login " style="height:45px"> 
    <v-btn small dark style="transform: translateY(10px); "  color="blue"  @click="$router.push('/')"  >
          <span class="mdi mdi-24px mdi-arrow-left-bold"></span> 
        </v-btn>
      <v-spacer></v-spacer>
    <LoginOut/>
    </div>  
    <v-dialog v-model="dialogLogin" max-width="500px">
      <DialogLogin @close="dialogLogin = false" />
    </v-dialog>
    <!-- 점보 -->
    <v-card   > 
    <div color="cyan" dark flat class="bg"   >  
           <div class="pa-5" style="flex: 2; z-index: 2">
              <div class="d-flex justify-center" style="position: relative;">
                <!-- <div class="logo"></div> -->
                <div >
                  <h2 class="mt-5 smallText" style="color: white;  text-shadow: 2px 2px 2px gray;"> AI트레이너 '챗PT' </h2>
                </div>
                <!-- <img class="boygirlS ml-2" :src="`${require('@/assets/boyGirl.png')}`" style="z-index: 1" /> -->
                <img class="jumboRight people" :src="`${require('@/assets/boyGirl.png')}`" style="z-index: 1" />
              </div>
              <h4   style="color: white;  text-shadow: 2px 2px 2px gray;">여러분을 위한 운동을 추천해드립니다.</h4> 
              
              <div class="mt-5 chatpt"  style="position: relative;">
                 <iframe src="https://www.chatbase.co/chatbot-iframe/nLTLEf1rb6W1LvA4bzyK8"  width="100%" height="800" frameborder="0" ></iframe>
              <div style="position: absolute;bottom:10px;background: white;width: 100%;">- 챗PT -</div>
                
                </div>
             </div>
          <!-- </v-img>  -->
        </div>
    </v-card>
  </v-container>
</template>

<script>
import DialogLogin from "@/components/DialogLogin.vue";
import LoginOut from "./LoginOut.vue";
export default {
  name: "PlayMore",
  components: { DialogLogin,LoginOut },
  data() {
    return {
      fab:false,
      today: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substring(0, 10),
      dialogLogin: false,
      loading: false,
      gameItemsTF:true,
      items: [], 
      itemsTF:true,
      gameItems: [],  
      bgImg: require("../assets/logo.png"),
    };
  },
  created() {
  }, 
  methods: {
    
  },
};
</script>
<style scoped>

.boygirlS{
  /* position: absolute;
  right: 50px;
  top: -40px; */
  width: 80px;
}
.people {
  position: absolute;
  right: -25px;
  top: -35px;
  width: 230px;
}
.jumboHeight{
  height: 200px;
}
.smallText{
  font-size: var(--big-size);
}
@media (max-width: 900px) {
  .jumboRight {
    display: none;
  }
}
@media (min-width: 900px) {
  .boygirlS {
    display: none;
  }
}
/* .login {
  margin-top: 20px;
} */
@media (max-width: 500px) {
  .smallText {
    font-size:30px;
  }
  .menuBox {
    border-radius: 10px;
  }
  /* .login {
    margin-top: 2px;
  } */
}
</style>
