<template>
  <v-card>
    <v-card-title>
      <h3>😀 PROFILE</h3>
    </v-card-title>
    <v-card-text>
      <v-form @submit.prevent="submitForm">
        <v-row class="px-3">
            <v-text-field v-model="name" prepend-icon="mdi-account-outline" label="Name"  placeholder="Name" ></v-text-field>
        <v-text-field v-model="email"  prepend-icon="mdi-email-outline" label="Email"  type="email"  placeholder="Email" ></v-text-field>
        <v-text-field v-model="team"  prepend-icon="mdi-account-group" label="Team" placeholder="Team"  ></v-text-field>
      <v-col cols="12">
        <v-btn    class="mr-auto" color="blue" dark @click="submit" :loading="loading" > 변경하기 </v-btn> 
        <v-img src="@/assets/images/triangle-light.png" class="triangle-bg"></v-img>
      </v-col>
    </v-row>
  </v-form>
</v-card-text>
</v-card>
</template>
<script>
import store from '@/store';

export default {
  data() {
    return {
      uid:this.$store.state.fireUser?this.$store.state.fireUser.uid:'',
      name: '',
      email: '',
      team: null,
      password: '',
      checkbox: false,
      user:'',
      loading:false
    };
  },
  created(){
    this.getData()
  },  
  methods: {
    submit() {
      this.loading=true
      this.$firebase.firestore().collection("user").doc(this.uid).update({ name: this.name,email:this.email,team:this.team})
        .then(()=>{this.loading=false}).catch((e) => console.log(e))
    },
    getData() {
      this.$firebase.firestore().collection("user").doc(this.uid).get()
        .then((e) => {
          this.name = e.data().name
          this.email = e.data().email
          this.team = e.data().team
        }).catch((e) => console.log(e))
    },
  }
};
</script>
<style>
.triangle-bg {
  position: absolute;
  width: 6.375rem;
  bottom: 0;
  right: 0;
}
</style>