<template>
  <v-container class="font-italic white--text pb-15 " >
    <!-- 로그인 -->
    <div class="d-flex login " style="height:45px"> 
      <v-btn small dark style="transform: translateY(10px); "  color="blue" @click="$router.push('/')"  >
          <span class="mdi mdi-24px mdi-arrow-left-bold"></span> 
        </v-btn>
      <v-spacer></v-spacer>
    <LoginOut/>
    </div>  
        <div color="cyan" dark flat class="bg" style="border-radius: 5px;" >  
           <div class="px-4 pt-3" style="flex: 2; z-index: 2">
              <div class="d-flex justify-center" style="position: relative;">
                <div style="display: flex; flex-direction: column; justify-content: center;">
                  <h1 style="font-size: var(--big-size);color: white;  text-shadow: 2px 2px 2px gray;">{{ title }}운동</h1>
                </div>
                <img class="boygirlS ml-2" :src="`${require('@/assets/character/boy3.png')}`" style="z-index: 1" />
              </div>
              <div class="d-flex mb-3">
                <!-- <v-btn class="ml-auto" icon small color="blue" dark @click="getAirtable" ><v-icon>mdi-36px mdi-refresh</v-icon> </v-btn> -->
              </div>
              <div class="d-flex justify-center" style="position: relative;">
                  <v-combobox class="mr-3" v-model="title" :items="workoutType" label="Combobox"   dense filled outlined solo/>
                  <v-combobox v-model="tool" :items="workoutTool" label="Combobox"  dense filled outlined solo/>
              </div>
             </div>
        </div>
        
    <!-- 운동 -->  
    <v-row class="mt-3">
      <!-- <v-col v-for="(n, i) in $store.state.workout" :key="i" cols="12" md="6"  > -->
      <v-col v-for="(n, i) in $store.state.workout.filter( (n) => workoutFilter(n.muscle, n.tools) )" :key="i" cols="12"  md="6" >
        <v-card :loading="loading" class="mx-auto pa-3" style="background: #08f0ff" @click="openDialog(n)" >
            <div class=" menuBox  "  >
              <template v-if="n.png && n.png.length > 0">
                <div style="align-items: center;display: flex;">
                  <v-img width="60" class="workImg" :src="n.png||''"/> 
                </div>
              </template>
              <div style="overflow-y: auto;">
                
                <h4 class="mt-3 mx-3 text-center">{{ n.title }} </h4>
                <h6 class="mt-3 mx-3 text-left" style="line-height: normal;">{{ n.short }} </h6>
              </div>
            </div>
        </v-card>
      </v-col>
    </v-row>
    <!-- 운동 다이알로그 -->
    <v-dialog  v-model="dialog" :overlay="false" max-width="500px"  transition="dialog-transition" persistent >
      <v-system-bar color="var(--logout-color)" height="40" dark >
        <v-spacer/>
        <v-btn small icon @click="colseDilog"><v-icon  color="white" >mdi-close</v-icon></v-btn>
      </v-system-bar>
      <v-container style="background:white;position:relative;">
        <!-- <template v-if="selectWork.gif && selectWork.gif.length > 0" > -->
        <template v-if="selectWork.gif" >
          <v-img width="200" class="mx-auto" :src="selectWork.gif"/> 
        </template>
        <h2>{{ selectWork.title }}</h2> 
        
          <v-btn-toggle v-model="dialMenu"   mandatory group class="d-flex blue--text" >
            <v-btn   value="timer" style="width:48%">   <h2>실시</h2>  </v-btn> 
            <v-btn   value="desc" style="width:48%"> <h2>방법</h2> </v-btn> 
          </v-btn-toggle>

        <div v-if="dialMenu==='desc'" style="background: rgba(0, 0,0, 0.1);max-height: 400px;overflow-y: auto;">
        <h5><pre class="text-left pa-3" style="white-space: pre-wrap;line-height: normal;">{{ selectWork.desc }}</pre></h5>
        </div>
        <div v-else>
          <DialWatch :selectWork="selectWork"  ref="dialwatch" @close="colseDilog"/>
        </div>
      </v-container >
    </v-dialog>

  </v-container>
</template>

<script>
import DialWatch from "@/components/DialWatch.vue"; 
import LoginOut from "./LoginOut.vue";
export default {
  name: "WorkOUt",
  components: {  LoginOut,DialWatch }, 
  data() {
    return {
      selectWork:'',
      fab:false,
      title:this.$route.params.name || "가슴",
      tool:'맨몸',
      today: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substring(0, 10),
      dialogLogin: false,
      loading: false,
      itemsTF:true,
      gameItemsTF:true,
      items: [], 
      gameItems: [], 
      showPT:false,
      workItems:[],
      workoutType:['가슴','다리','등','복근','삼두','어깨','이두','삼두','힙','전신'],
      workoutTool:['맨몸','덤벨','바벨','케이블','머신','기타'] ,
      dialog:false,
      dialMenu:'timer'
    };
  },
  created() {
    // this.getAirtable()
  },
  methods: { 
    openDialog(n){
      this.dialog=true;
      this.selectWork=n
      this.$nextTick(() => {
      // this.$refs.dialwatch.timerEnd()
      });
    },
    colseDilog(){
      this.dialog=false
      this.$nextTick(() => {
      this.$refs.dialwatch.timerEnd()
      });
    }, 

    workoutFilter(muscle,tool) {
      if (muscle==this.title&&tool==this.tool) { 
        return true;
      } else {
        return false;
      }
    },
    routLink(n,a) {
      this.$router.push({
        name: a,
        params: { id: n.id },
        // params: { id: n.id, data: n },
      });
    },
    logout() {
      this.$firebase.auth().signOut();
      location.reload(true)
    }, 
  },
};
</script>
<style scoped>   
  
.itemRow {
  flex-wrap: nowrap;
  overflow-x: auto;
} 
.smallText {
  text-transform: uppercase;
} 
.boygirlS{
  width: 80px;
} 
.jumboHeight{
  height: 200px;
}  
.workImg{
  min-width: 150px;
  height:150px;
  border-radius: 5px;
  background-repeat: no-repeat;
  background-size: contain;

}
  .menuBox {
    display: flex;  
    height: 150px;
    cursor: pointer;
    transition: all 0.5s;
    background: white;
}
.workmain{
    background-image: url(@/assets/bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  letter-spacing: 0.5px;
  height: 100%;
}
  .menuBox:hover {
  background: rgba(255, 255, 255, 0.3); 
} 
@media (max-width: 500px) {
  .smallText {
    font-size:medium;
  }
 
}
</style>
