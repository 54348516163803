<template>
  <v-container class="font-italic white--text pb-15">
    <!-- 로그인 -->
    <div class="d-flex login " style="height:45px"> 
    <v-btn small dark style="transform: translateY(10px); "  color="blue"  @click="$router.push('/')"  >
          <span class="mdi mdi-24px mdi-arrow-left-bold"></span> 
        </v-btn>
      <v-spacer></v-spacer>
    <LoginOut/>
    </div>  
    <v-dialog v-model="dialogLogin" max-width="500px">
      <DialogLogin @close="dialogLogin = false" />
    </v-dialog>
    <!-- 점보 -->
    <div color="cyan" dark flat class="bg pa-4" style="border-radius: 5px;" >  
           <div class="px-4 pt-3" style="flex: 2; z-index: 2">
              <div class="d-flex justify-center" style="position: relative;">
                <img class="  mr-2" :src="`${require('@/assets/images/trophy.png')}`" style="z-index: 1;width: 80px;" />
                <div style="display: flex; flex-direction: column; justify-content: center;">
                  <h1 style="font-size: var(--big-size);color: white;  text-shadow: 2px 2px 2px gray;">CHALLENGE</h1>
                </div>
              </div> 
              <!-- <div class="d-flex justify-center" style="position: relative;">
                <v-combobox class="mr-3" v-model="title" :items="workoutType" label="Combobox"   dense filled outlined solo/>
                <v-combobox v-model="tool" :items="workoutTool" label="Combobox"  dense filled outlined solo/>
              </div> -->
            </div>
    </div>
    <!-- 새로운운동 타이틀 -->
    <div class="d-flex justify-space-between py-5">
      <!-- <img class="mt-3" src="@/assets/images/trophy.png" style="width:30px;height:35px;" ><img>
      <h3 class="mt-5 text-left italic title" style="color: #fcce2d">Challenge </h3> -->
      <v-spacer></v-spacer>
      <!-- <v-btn class="my-5" rounded small color="var(--main-color)" @click="('/create')">CREATE</v-btn> -->
      <v-btn  small rounded color="blue"  @click="$router.push('/create')" v-if="$store.state.fireUser&&$store.state.fireUser.uid"> <h3 style="color: white;">CREATE</h3>  </v-btn>
    </div>
    <!-- 새로운운동 아이템들 -->
    <v-row class="itemRow " v-if="itemsTF" >
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-skeleton-loader max-height="150" type="card" />
      </v-col>
    </v-row>
    <v-row class="itemRow" v-else >
      <!-- <v-col v-for="(n, i) in items.filter( (n) => n.type === 'workout' && dateCompare(n.endDate) )" -->
      <v-col v-for="(n, i) in items" :key="i" cols="12" sm="6" md="4" lg="3" >
      <!-- <v-col v-for="(n, i) in items.filter( (n) => dateCompare(n.endDate) )" :key="i" cols="12" sm="6" md="4" lg="3" > -->
        <v-card :loading="loading" class="mx-auto" style="background: #08f0ff"  @click="routLink(n,'play')" >
          <v-img max-height="150" :src="`${require('@/assets/bg.png')}`" > 
            <div class="ma-3 pb-5 rounded-lg white--text menuBox workout">
              <v-card-title><h3>{{ n.title }}</h3></v-card-title>
              <v-card-text>
                <v-row class="mx-0">
                  <v-rating :value="n.rating" color="amber" dense half-increments readonly size="14" ></v-rating>
                  <v-spacer></v-spacer>
                  <div class="grey--text ms-4">
                    <span style="color: var(--second-color)">{{ n.team }}</span>
                  </div>
                </v-row>
                <div class="mt-5 text-subtitle-1">
                  {{ n.content }}
                </div>
              </v-card-text>
            </div>
          </v-img>
          <v-card-actions >
            <v-icon >mdi-timetable</v-icon> {{ dateCompare(n.endDate)?n.endDate:'챌린지기간 만료' }}
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DialogLogin from "@/components/DialogLogin.vue";
import LoginOut from "./LoginOut.vue";
export default {
  name: "PlayMore",
  components: { DialogLogin,LoginOut },
  data() {
    return {
      fab:false,
      today: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substring(0, 10),
      dialogLogin: false,
      loading: false,
      gameItemsTF:true,
      // items: [], 
      // itemsTF:true,
      gameItems: [],  
      bgImg: require("../assets/logo.png"),
    };
  },
  created() {
    this.getWorkRoom();
  },
  computed: {
    items() {
      return this.$store.state.workRooms;
    },
    itemsTF() {
    return this.$store.state.workRooms.length === 0;
   },
  },
  methods: {
    async getWorkRoom() {
          try {
            const sn = await this.$firebase.firestore().collection("workout").get();
            const workRooms = sn.docs.map((e) => e.data());
             workRooms.sort((a, b) => new Date(b.startDate) - new Date(a.startDate));
          } catch (e) {
            console.error(e);
          }
        },
    getData() {
      this.$firebase.firestore().collection("workout").get().then((sn) => {
          this.items = sn.docs.map((e) => e.data());
        }).catch((e) => console.log(e)).finally(()=>{this.itemsTF=false});
    },
    dateCompare(day) {
      if (day) {
        const date1 = new Date(this.today);
        const date2 = new Date(day);
        return date1 <= date2;
      } else {
        return true;
      }
    },
    routLink(n,a) {
      this.$router.push({
        name: a,
        params: { id: n.id },
        // params: { id: n.id, data: n },
      });
    },
    logout() {
      this.$firebase.auth().signOut();
      location.reload(true)
    },
  },
};
</script>
<style scoped>
.menuBox {
  height: 100%;
  cursor: pointer;
  transition: all 0.5s;
  backdrop-filter: blur(5px);
  border-radius: 20px;
  background: rgba(255, 255, 0, 0.5);
  box-shadow: 0 25px 45px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.menuBox:hover {
  background: rgba(255, 255, 255, 0.3); 
}
.menuBox.workout {
  background: rgba(0, 0, 0, 0.3);
  border: none;
  border-right: none;
  border-bottom: none;
  overflow: hidden;
}
 
.menuBox.workout:hover {
  background: rgba(255, 255, 0, 0.5);
}
.title {
  text-shadow: 2px 2px 2px gray;
}
.itemRow {
  flex-wrap: wrap;
  /* overflow-x: auto; */
}
.menuBox:hover h3 {
  transition: all 0.2s;
  transform: scale(1.1);
}
.smallText {
  text-transform: uppercase;
}
/* .login {
  margin-top: 20px;
} */
@media (max-width: 500px) {
  .smallText {
    font-size: x-large;
  }
  .menuBox {
    border-radius: 10px;
  }
  /* .login {
    margin-top: 2px;
  } */
}
</style>
