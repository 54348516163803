<!-- eslint-disable vue/valid-v-slot -->
<template>
  <v-card class="cardRank">
    <!-- 랭크제목줄 -->
    <v-card-title class="text-h5 white--text mb-3 rankTop" style="padding: 5px 12px 0 12px"> 
      RANK <v-spacer></v-spacer>
    </v-card-title>
    <!-- 랭크테이블 -->
    <v-card-text>
      <!-- <v-data-table dark :headers="headers" :items="items" sort-by="rank" fixed-header hide-default-footer >
        <template v-slot:item.name="{ item }"> 
          <Avataaars :width="30" :height="30" :avatarOptions="item.options" />
          {{ item.name }}
        </template>
      </v-data-table> -->
      <v-simple-table dark>
        <thead>
          <tr >
            <th class="text-center"  >순위</th>
            <th class="text-right"></th>
            <th class="text-center">이름</th>
            <th class="text-center" v-if="maxAdd==='max'">최고기록</th>
            <th class="text-center" v-else>누적기록</th>
            <th class="text-center">도전횟수</th>
          </tr>
        </thead>
        <tbody>
          <tr  v-for="(a,i) in items"  :key="i" >
            <td class="text-center">{{ a.rank }}</td> 
            <td class="text-center" style="width: 30px;padding:0;margin: 0;" > <Avataaars :width="35" :height="35" :avatarOptions="a.options" />    </td> 
            <td class="text-center " style="width: 70px;padding:0;margin: 0;" > 
              <div class="d-flex flex-column">
              <span> {{ a.name }}</span> 
              <small>{{ a.team }}</small>
              </div>
            </td>              
            <td class="text-center">{{ maxAdd==='max'?a.record:a.recordSum }}</td>
            <td class="text-center">{{ a.recordRepeat }}</td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "FullRank",
  // props: ['items','rank',"id",'maxAdd'],
  data() {
    return {
      id: this.$route.params.id || "",
      items: [],
      params:'',
      members:'',
      maxAdd:'',
      headers: [
        { value: "rank", text: "순위", align: "center" },
        { value: "name", text: "이름", align: "center", sortable: false }, 
        { value: "record", text: "최고기록", align: "center", sortable: false }, 
      ],
    };
  },

  created() {
    console.log('id',this.id)
    // this.getData();
    this.getRank();
  },
  destroyed() {
  },
  methods: { 
    getData() {
      if (!this.id) {return }
      this.$firebase.firestore().collection("workout").doc(this.id).get()
        .then((e) => {this.params = e.data()}).catch((e) => console.log(e))
    },
    
    async getRank(){
      if (!this.id) {return }
      const order = this.params.maxAdd === 'max' ? 'record' : 'recordSum';
        try { 
          const rankSnapshot = await this.$firebase.firestore().collection("workout").doc(this.id)
            .collection("rank").orderBy(order, "desc").get();
        
          const items = rankSnapshot.docs.map((e) => e.data());
                this.members = items.length
                const items2 = [];
                items.forEach((e) => {
                  const rank = items.indexOf(e) + 1;
                  items2.push({ ...e, rank: rank });
                });
                this.items = items2;  
        } catch (error) {
          console.error(error);
        }
    }
    // getRank(){
    //   if (!this.id) {return }
    //   this.$firebase.firestore().collection("workout").doc(this.id).get()
    //     .then((e) => {this.params = e.data()}).catch((e) => console.log(e))
    //     .finally(()=>{

    //     const order = this.params.maxAdd==='max'?'record':'recordSum'
    //   this.$firebase.firestore().collection("workout").doc(this.id)
    //     .collection("rank").orderBy(order, "desc").limit(10)
    //     .get().then((sn) => {
    //       const items = sn.docs.map((e) => e.data());
    //       this.members = items.length
    //       const items2 = [];
    //       items.forEach((e) => {
    //         const rank = items.indexOf(e) + 1;
    //         items2.push({ ...e, rank: rank });
    //       });
    //       this.items = items2; 
    //     }).catch((e) => {console.log(e)}) 
    //     })
    // }
    
  },
};
</script>

<style lang="scss" scoped>
v-data-table {
  flex-direction: row;
}
.rankTop {
  background-color: #272727;
  border-bottom: 3px solid var(--second-color);
}
.cardRank {
  overflow: auto;
  // background-color: #595959;

  box-shadow: 0 25px 45px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
</style>
