import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    fireUser: null,
    admin: false,
    userData: null,
    workRooms:[],
    workout:[]
  },
  getters: {},
  mutations: {
    //로그인- 관리자판정
    authTF(state, fu) {
      state.fireUser = fu;
      state.admin = false;
    
      if (!fu) {
        state.userData = fu;
        return;
      }
      if (fu.uid === process.env.VUE_APP_FIREBASE_Admin) {
        state.admin = true;
      } else {
        state.admin = false;
      }
    },
    //유저데이터 저장
    setfireUser(state, data) {
      state.fireUser = data;
    },
    setUserData(state, data) {
      state.userData = data;
    },
    setWorkout(state, data) {
      state.workout = data;
    },
    setWorkRooms(state, data) {
      state.workRooms = data;
    },
  },
});
