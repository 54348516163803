<template>
     <v-container class="white--text pb-15 workmain" style="background-color: aqua;" >
    <!-- 로그인 -->
    <div class="d-flex login " style="height:45px;" > 
        <v-btn small dark style="transform: translateY(10px); "  color="blue" @click="$router.push('/')"  >
          <span class="mdi mdi-24px mdi-arrow-left-bold"></span> 
        </v-btn>
      <v-spacer></v-spacer>
    <LoginOut/>
    </div>  
    <VRow class="match-height">
        <VCol cols="12" >  <DashProfile /> </VCol>
      <VCol cols="12"  >  <DashWorkout /> </VCol>

        <VCol cols="12"   >  
          <h2 class="mt-2 black--text text-left">🚀 CHALLENGE</h2>
          <v-card class="position-relative py-3">
              <v-card-text>
                <h2 class="text-left">🎉 누적챌린지<span class="red--text">{{sumData}}</span>회</h2>
              </v-card-text>
              <v-card-text class="text-center">
                <v-btn size="small" color="info"  @click="$router.push('/playmore')"  >도전하기</v-btn> 
              </v-card-text>
              <v-img src="@/assets/images/triangle-light.png" class="triangle-bg"></v-img>
              <v-img src="@/assets/images/trophy.png" class="trophy"></v-img>
              <v-card-text>
            <v-simple-table class="table-rounded" v-if="datas.length">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center" v-for="(header,i) in headers" :key="i">{{ header }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(row,i) in datas" :key="i">
                    <td>
                      <div class="d-flex flex-column">
                        <span class="d-block font-weight-semibold text--primary text-truncate">{{ row.title }}</span>
                        <small>{{ row.date }}</small>
                      </div>
                    </td>
                    <td >{{ row.recordRepeat }}</td>
                    <td>{{ row.recordSum }}</td>
                    <td>
                      <v-chip small color="info" class="font-weight-medium" >
                        {{ row.rank }}
                      </v-chip>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <h3 class="my-5" v-else >😂참여한 챌린지가 아직 없습니다.</h3>
                
              </v-card-text>
          </v-card>
        </VCol>

    </VRow>
    </v-container>
  </template>
  
  <script>
  import DashWorkout from '@/dashboard/DashWorkout.vue'
    import DashProfile from '@/dashboard/DashProfile.vue'
import LoginOut from "./LoginOut.vue";
  
  export default {
    components: {LoginOut, DashProfile, DashWorkout },
    data() {
      return {
        uid:this.$store.state.fireUser?this.$store.state.fireUser.uid:'',
        datas:{}, 
        headers: [
          '챌린지',
          '도전횟수',
          '누적기록',
          '순위',
        ],
        challenge:'',
        sumData:'' 
      }
    },
    created() {
    this.getChallenge();
    },
  methods: { 
      async getChallenge() {
        try {
                const sn = await this.$firebase.firestore().collection('record').doc(this.$store.state.fireUser.uid).collection('challenge').get();
                const datas = await Promise.all(sn.docs.map(async (e) => {
                  const data = e.data()
                  const order = data.order==='add'?'recordSum':'record'
                  const rankSnapshot = await this.$firebase.firestore().collection("workout").doc(data.roomId).collection("rank").orderBy(order, "desc").get();
                  const items =   rankSnapshot.docs.map((e) => e.data());
                  const rank = items.findIndex(item => item.uid === this.uid);
                 return {...data,rank:rank+1 }
                }));
             this.datas = datas
             this.sumData = datas.reduce((sum, item) => sum + item.recordRepeat, 0);
            //  console.log(this.datas,this.sumData)
             } catch (error) {
               console.error("Failed to get challenge:", error);
             }
      }
    },
  }
  </script>
  <style scoped>
.workmain{
    background: red;
    background-image: url(@/assets/bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  letter-spacing: 0.5px;
  height: 100%;
}
.triangle-bg {
  position: absolute;
  width: 10.375rem;
  bottom: 0;
  right: 0;
}

.trophy {
  position: absolute;
  width: 4.9375rem;
  top: 1rem;
  right: 1rem;
}
</style>
  