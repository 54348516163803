<template>
  <div>
  <h2 class="mt-3 text-left black--text">🚀 WORKOUT</h2>
  <v-card class="py-3"> 
    <v-card-text>
      <v-img src="@/assets/images/triangle-light.png" class="triangle-bg"></v-img>
      <h2 class="text-left mb-6">
         🎉 이번달 누적 운동 <span style="color: red;">{{ workData[today.substring(0, 7)] }}</span> SET  
      </h2>

  <v-row>
    <v-col v-for="(item,i) in items" :key="i" cols="6" sm="3" >
      <div class="d-flex align-center">
        <div class="me-3">
          <v-card color="#feb439"  size="70" class="elevation-1" @click="routLink(item.title)">
            <!-- <v-icon color="white" size="24">{{ item.icon }}</v-icon> -->
            <v-img :src="`${require('@/assets/muscle/'+item.title+'.png')}`" width="40" height="80" class="ma-1">  </v-img>
          </v-card>
        </div>

        <div class="d-flex flex-column">
          <span> {{ item.title }} </span>
          <span class="text-h6 font-weight-medium">{{ item.set }}</span>
          <span> set </span>
        </div>
      </div>
    </v-col>
  </v-row>
</v-card-text>
</v-card>
</div>
</template>

<script>
export default {
  data() {
    return {
      today: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substring(0, 10),
      workData:{},
      items: [{ title: '가슴',set: '12'}, { title: '등',set: '12',} ]
    };
  },
  created() {
    this.getData()
  },  
  methods: { 
      async getData() {
      const month = this.today.substring(0, 7);
      const items = []
      this.$firebase.firestore().collection('record').doc(this.$store.state.fireUser.uid).get().then((e) => {
        if (e.data()) {
          // console.log(e.data(), month);
          const workData =  e.data()
          this.workData = workData
          const items = [
            { title: '가슴',set: workData[this.today.substring(0, 7)+'-가슴']||0},{ title: '어깨',set: workData[this.today.substring(0, 7)+'-어깨']||0},
            { title: '이두',set: workData[this.today.substring(0, 7)+'-이두']||0},{ title: '등',set: workData[this.today.substring(0, 7)+'-등']||0},
            { title: '삼두',set: workData[this.today.substring(0, 7)+'-삼두']||0},{ title: '복근',set: workData[this.today.substring(0, 7)+'-복근']||0},
            { title: '다리',set: workData[this.today.substring(0, 7)+'-다리']||0},{ title: '전신',set: workData[this.today.substring(0, 7)+'-전신']||0}, ]
          this.items = items
          this.$firebase.firestore().collection('record').doc(this.$store.state.fireUser.uid).collection(month).get()
          .then((sn) => {
            const data = sn.docs.map((e) => e.data());
          }).catch((error) => {
            console.log(error);
          });
        }
        if (!e.data()) {
          return;
        }
      });
    },
    routLink(n) {
            this.$router.push({
                name: 'workout',
                params: { name: n},
            });
        },

  },
};
</script>

<style scoped> 
.triangle-bg {
  position: absolute;
  width: 10.375rem;
  bottom: 0;
  right: 0;
}
 
</style>