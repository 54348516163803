<template>
<div style="padding: 0" >
  <v-container class="mt-5" style="padding: 0"  >
    <v-container class="text-center" style="padding:0"  v-if="!completeTF" >
      <h2 class="text-center"> {{ formattedTime }} </h2>
      <div class="d-flex justify-space-around mx-auto my-2" style="padding:0">
        <div>
         <v-progress-circular size="130" :width="15" :value="(count/rep)*100" color="#08f0ff" style="width:130px">
         <div>
            <h3 >REP</h3>
            <h1  >{{count}}</h1>
         </div>
         </v-progress-circular>
        </div>
        <div  >
          <div class="mt-1" >
            <v-btn icon @click="tempoSet('plus')"><v-icon>mdi-chevron-double-up</v-icon></v-btn>
            <h5>TEMPO</h5>
            <h5 class="cyan--text" >({{tempo/1000}}sec)</h5>
            <v-btn icon @click="tempoSet('minus')"><v-icon>mdi-chevron-double-down</v-icon></v-btn>
          </div>
        </div>
        <div>
         <v-progress-circular size="130" :width="15" :value="(restime/timer)*100" color="#08f0ff"  style="width:130px">
            <div>
            <h3  >REST</h3>
            <h1  >{{timer}}</h1>
            </div>
         </v-progress-circular>
        </div>
      </div>
     <v-progress-linear   v-model="progress" buffer-value="10" color="#08f0ff" stream height="25"  >
      <!-- <strong>{{ Math.round(progress) }}%</strong> -->
      <strong>{{ setnum }} SET</strong>
    </v-progress-linear>
    <div class="d-flex mt-5 justify-center" style="position: relative;">
        <v-combobox v-model="set" :items="sets" label="SET"  dense filled outlined  />
        <v-combobox v-model="weight" :items="weights" label="WEIGHT"  dense filled outlined  class="ml-3" v-if="selectWork.tools!='맨몸'"/>
        <v-combobox v-model="rep" :items="reps" label="REP"  dense filled outlined  class="mx-3"/>
        <v-combobox v-model="timer" :items="timers" label="REST"  dense filled outlined />
    </div>
  </v-container>
  <v-container v-else>
    <h2 class="my-10">🎉 운동을 완료했습니다! 🎉</h2>
  </v-container>
      <!-- 시작정지 -->
     <div class="mb-3 mx-auto" style="max-width:400px">
        <v-btn rounded   dark block color="info" @click="playGame" v-if="!start"  >   {{ko?'START':'시작'}} </v-btn>
        <div class="d-flex" v-if="start&&!completeTF">
          <v-btn rounded class="mr-2 " dark color="var(--logout-color)" @click="timerStop" style="flex:1" v-if="!restart" >{{ko?'Pause':'일시정지'}}  </v-btn>
          <v-btn rounded class="mr-2 " color="info" @click="replay" style="flex:1" v-else >  {{ko?'RESTART':'재시작'}} </v-btn>
          <v-btn rounded dark color="var(--logout-color) " @click="timerEnd"  style="flex:1">  {{ko?'STOP':'정지'}} </v-btn>
        </div>
        <div class="d-flex" v-if="completeTF">
          <v-btn rounded class="mr-2 " color="info" @click="replay" style="flex:1"  >  {{ko?'RESTART':'재시작'}} </v-btn>
          <v-btn rounded dark color="var(--logout-color) " @click="save"  style="flex:1">  {{ko?'SAVE':'저장'}} </v-btn>
        </div>
     </div>
    </v-container>
</div>
</template>

<script>
export default {
  name: 'DialWatch',
  props:['selectWork'],
  data () {
    return {
      uid:this.$store.state.fireUser?this.$store.state.fireUser.uid:'',
      today: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substring(0, 10),
      formattedTime: '00:00:00',
      part: 'abs',
      workout: [],
      kinds: [],
      ko: false,
      steps: 7,
      kindNum: 0,
      clearTF: false,
      set: 3,
      weight: 10,
      rep: 10,
      timer: 60,
      sets: [1,2,3,4,5,6,7,8,9,10],
      reps: [5,10,15,20,25,30,35,40,45,50,100],
      weights: [5,10,15,20,25,30,35,40,45,50,100],
      timers: [10,20,30,40,50,60,70,80,90,100],
      restime: 0,
      setnum: 0,
      count: 0,
      timerInterval: '',
      starTimeOut: '',
      countInterval: '',
      start: false,
      restart: false,
      stop: true,
      show: false,
      successPoint: 0,
      voice: '남',
      progress: 0,
      tempo: 2500,
      audio: null,
      countdown: null,
      endbell: null,
      timerSound: null,
      // audio: new Audio(require('@/assets/countdown.mp3')),
      // countdown: new Audio(require('@/assets/countdown.mp3')),
      // endbell: new Audio(require('@/assets/endbell.mp3')),
      // timerSound: new Audio(require('@/assets/timer.mp3')),
      currentTimer: 0,
      timerState: 'stopped',
      ticker: undefined,
      completeTF:false,
      saveWeight:[],
      saveSet:[],
      saveRep:[]
    }
  },

  destroyed () {
    this.timerStop()
    if(this.ticker){window.clearInterval(this.ticker) }
    if(this.countInterval){window.clearInterval(this.countInterval) }
    if(this.timerInterval){window.clearInterval(this.timerInterval) }
    
    // window.clearInterval(this.countInterval)
    // window.clearInterval(this.timerInterval)
  }, 
  created() {
    this.audio = new Audio(require('@/assets/countdown.mp3'));
    this.countdown = new Audio(require('@/assets/countdown.mp3'));
    this.endbell = new Audio(require('@/assets/endbell.mp3'));
    this.timerSound = new Audio(require('@/assets/timer.mp3'));
  },
  methods: {

    // timerEnd () {
    //   this.timePause()
    //   this.count = 0
    //   this.setnum = 0
    //   this.progress = 0
    //   this.restime = 0
    //   this.start = false
    //   this.stop = true
    //   this.completeTF=false
    //   this.successPoint = 0
    //   const countPlay = this.countdown.play()
    //   if (countPlay !== undefined) { countPlay.then(() => { this.countdown.pause() }) }
    //   const audioPlay = this.audio.play()
    //   if (audioPlay !== undefined) { audioPlay.then(() => { this.audio.pause() }) }
    //   const endbellPlay = this.endbell.play()
    //   if (endbellPlay !== undefined) { endbellPlay.then(() => { this.endbell.pause() }) }
    //   const timerSoundPlay = this.timerSound.play()
    //   if (timerSoundPlay !== undefined) { timerSoundPlay.then(() => { this.timerSound.pause() }) }

    //   clearTimeout(this.starTimeOut)
    //   clearTimeout(this.playTimeOut)
    //   clearInterval(this.countInterval)
    //   clearInterval(this.timerInterval)
    //   this.timeStop()
    // },
    timerEnd() {
  this.timePause();
  this.count = 0;
  this.setnum = 0;
  this.progress = 0;
  this.restime = 0;
  this.start = false;
  this.stop = true;
  this.completeTF = false;
  this.successPoint = 0;
  clearTimeout(this.starTimeOut);
  clearTimeout(this.playTimeOut);
  clearInterval(this.countInterval);
  clearInterval(this.timerInterval);
  this.timeStop();
  this.pauseSounds(); // 소리를 일시정지하는 함수 호출
},
pauseSounds() {
  if (this.countdown) {
    this.countdown.pause();
  }
  if (this.audio) {
    this.audio.pause();
  }
  if (this.endbell) {
    this.endbell.pause();
  }
  if (this.timerSound) {
    this.timerSound.pause();
  }
},

    tempoSet (num) {
      if (num === 'plus') {
        this.tempo += 500
      } else {
        if (this.tempo > 500) {
          this.tempo -= 500
        }
      }
    },
    cancel () {
      this.timerEnd()
      this.$emit('cancel')
    },
    countSound () {
      const audio = new Audio(require('../assets/mp3/' + (this.count % 10) + '.mp3'))
      const audioPlay = audio.play()
      if (audioPlay !== undefined) {
        audioPlay.then(() => { audio.play() })
          .catch(e => { audio.pause() })
      }
    },

    playGame () {
      this.timeStart()
      this.completeTF=false
      this.clearTF = false
      this.restart = false
      if (this.setnum > this.set) {
        this.timerEnd()
        return
      }
      this.restime = this.timer
      this.start = true
      this.stop = false
      const countPlay = this.countdown.play()
      
      this.saveRep.push(this.rep) 
      if(this.selectWork.tool!='맨몸'){
        this.saveWeight.push(this.weight) 
      }else{
        this.saveWeight.push(1) 
      }
      if (countPlay !== undefined) {
        countPlay.then(() => { this.countdown.play() })
          .catch(e => { console.log(e) })
      }
      this.starTimeOut = setTimeout(() => {
        this.countTimer()

      }, 5000)
    },
    complete(){
      this.completeTF=true
      this.timePause()
    },
    save(){
      this.$emit('close');
      const id = Date.now().toString()
      const year = this.today.substring(0, 4)
      const month = this.today.substring(0, 7)
      const monthBuwi = this.today.substring(0, 7)+'-'+this.selectWork.muscle
      const set = this.set
      const data = {
        id: id,
        date: this.today,
        year:year,
        month: month,
        set: set,
        workTime: this.formattedTime,
        name: this.selectWork.title,
        body: this.selectWork.muscle,
        tool: this.selectWork.tools,
        rep: this.saveRep,
        weight:this.saveWeight
      }
      // console.log(data)
      this.$firebase.firestore().collection('record').doc(this.$store.state.fireUser.uid).get().then((e) => {
        if (e.data() ) { 
          this.$firebase.firestore().collection('record').doc(this.$store.state.fireUser.uid)
          .update({ total: this.$firebase.firestore.FieldValue.increment(set),
            [monthBuwi]: this.$firebase.firestore.FieldValue.increment(set),
            [month]: this.$firebase.firestore.FieldValue.increment(set),
            [year]: this.$firebase.firestore.FieldValue.increment(set)}).then(()=>{
            this.$firebase.firestore().collection('record').doc(this.$store.state.fireUser.uid).collection(month).doc(id).set(data).catch(e => console.log(e))
            // this.$firebase.firestore().collection('record').doc(this.$store.state.fireUser.uid).collection(this.today).doc(id).set(data).catch(e => console.log(e))
          })  
        }
        if (!e.data()) { 
          this.$firebase.firestore().collection('record').doc(this.$store.state.fireUser.uid)
          .set({ total: this.$firebase.firestore.FieldValue.increment(set),
            [monthBuwi]: this.$firebase.firestore.FieldValue.increment(set),
            [month]: this.$firebase.firestore.FieldValue.increment(set),
            [year]: this.$firebase.firestore.FieldValue.increment(set)}).then(()=>{
            this.$firebase.firestore().collection('record').doc(this.$store.state.fireUser.uid).collection(month).doc(id).set(data).catch(e => console.log(e))
            // this.$firebase.firestore().collection('record').doc(this.$store.state.fireUser.uid).collection(this.today).doc(id).set(data).catch(e => console.log(e))
          }) 
        }
      })
    },
    countTimer () {
      this.countInterval = setInterval(() => {
        if (this.count < this.rep) {
          this.count++
          this.countSound()
        } else {
          this.clear()
        }
      }, this.tempo)
    },

    clear () {
      this.setnum++
      this.successPoint++
      this.progress = (this.successPoint / this.set) * 100
      this.clearTF = true
      const endbell = this.endbell.play()
      if (endbell !== undefined) { endbell.then(() => { this.endbell.play() }) }
      this.count = 0
      clearTimeout(this.starTimeOut)
      clearInterval(this.countInterval)
      this.intervalTimer()
    },
    // 운동 마치기 또는 휴식 카운터
    intervalTimer () {
      if (this.setnum === this.set) {
        if(this.$store.state.fireUser){
          this.complete()
        }else{
          this.timerEnd()
        }
        return
      }
      this.timerSound.loop = true
      const timerSoundPlay = this.timerSound.play()
      if (timerSoundPlay !== undefined) {
        timerSoundPlay.then(() => { this.timerSound.play() })
          .catch(e => { console.log(e) })
      }
      // 휴식시간이 다될때까지 카운터 줄이기
      this.timerInterval = setInterval(() => {
        if (this.restime > 0) {
          this.restime--
        } else {
          this.timerSound.pause()
          clearInterval(this.timerInterval)
          clearTimeout(this.starTimeOut)
          this.playTimeOut = setTimeout(() => {
            this.playGame()
          }, 2000)
        }
      }, 1000)
    },

    timerStop () {
      this.timePause()
      this.restart = true
      this.stop = true
      this.countdown.pause()
      this.endbell.pause()
      this.timerSound.pause()

      if(this.starTimeOut){ clearTimeout(this.starTimeOut) }
      if(this.playTimeOut){ clearTimeout(this.playTimeOut) }
      if(this.timerInterval){ clearTimeout(this.timerInterval) }
      if(this.countInterval){ clearTimeout(this.countInterval) }
      // 2초마다 실행되는 개수세기 정지
     
      // clearTimeout(this.playTimeOut)
      // clearInterval(this.timerInterval)
      // clearInterval(this.countInterval)
    },

    tick () { this.ticker = setInterval(this.updateTimer, 1000) },
    updateTimer () {
      const hours = Math.floor(this.currentTimer / 3600)
      const checkMinutes = Math.floor(this.currentTimer / 60)
      const seconds = this.currentTimer % 60
      const minutes = checkMinutes % 60
      this.formattedTime = `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`
      this.currentTimer++
    },
    timeStart () {
      if (this.timerState !== 'running') {
        this.tick()
        this.timerState = 'running'
      }
    },
    timePause () {
      window.clearInterval(this.ticker)
      this.timerState = 'paused'
    },
    timeStop () {
      window.clearInterval(this.ticker)
      this.currentTimer = 0
      this.formattedTime = '00:00:00'
      this.timerState = 'stopped'
    },
    replay () {
      this.timeStart()
      if (this.clearTF) {
        this.restart = false
        this.timerSound.loop = true
        const timerSoundPlay = this.timerSound.play()
        if (timerSoundPlay !== undefined) {
          timerSoundPlay.then(() => { this.timerSound.play() })
            .catch(e => { console.log(e) })
        }
        this.timerInterval = setInterval(() => {
          if (this.restime > 0) {
            this.restime--
          } else {
            this.timerSound.pause()
            clearInterval(this.timerInterval)
            clearTimeout(this.starTimeOut)
            this.playTimeOut = setTimeout(() => {
              this.playGame()
            }, 2000)
          }
        }, 1000)
      } else {
        this.start = true
        this.restart = false
        this.stop = false
        const countPlay = this.countdown.play()
        if (countPlay !== undefined) {
          countPlay.then(() => { this.countdown.play() })
            .catch(e => { console.log(e) })
        }
        this.starTimeOut = setTimeout(() => {
          this.countTimer()
        }, 5000)
      }
    },
    // restart () {
    //   this.stop = true
    //   this.start = false
    // },

    plus (a) {
      if (a === 'set') {
        this.set = this.set + 1
      } else if (a === 'rep') {
        this.rep = this.rep + 5
      } else if (a === 'timer') {
        this.timer = this.timer + 5
      }
    },
    minus (a) {
      if (a === 'set' && this.set > 0) {
        this.set = this.set - 1
      } else if (a === 'rep' && this.rep > 0) {
        this.rep = this.rep - 5
      } else if (a === 'timer' && this.timer > 5) {
        this.timer = this.timer - 5
      }
    }

  }
}
</script>

<style>

</style>
