import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import PlayView from "../views/PlayView.vue";
import GameView from "../views/GameView.vue";
import CreateView from "../views/CreateView.vue";
import CreateGame from "../views/CreateGame.vue";
import PlayMore from "../views/PlayMore";
import WorkOut from "../views/WorkOut";
import GameMore from "../views/GameMore";
import AiPlaner from "../views/AiPlaner";
import FullRank from "../components/FullRank";
import dashboard from "../views/DashBoard";
import chatpt from "../views/CahtPt";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/play/:id",
    name: "play",
    component: PlayView,
  },
  {
    path: "/game/:id",
    name: "game",
    component: GameView,
  },
  {
    path: "/create",
    name: "create",
    component: CreateView,
  },
  {
    path: "/creategame",
    name: "creategame",
    component: CreateGame,
  },
  {
    path: "/fullrank/:id",
    name: "fullrank",
    component: FullRank,
  },
  {
    path: "/playmore",
    name: "playMore",
    component: PlayMore,
  },
  {
    path: "/workout",
    name: "workout",
    component: WorkOut,
  },
  {
    path: "/gamemore",
    name: "GameMore",
    component: GameMore,
  },
  {
    path: "/aiplaner",
    name: "AiPlaner",
    component: AiPlaner,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: dashboard,
  },
  {
    path: "/chatpt",
    name: "chatpt",
    component: chatpt,
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
