<!-- eslint-disable vue/valid-v-slot -->
<template>
  <v-card class="cardRank">
    <!-- 랭크제목줄 -->
    <v-card-title class="text-h5 white--text mb-3 rankTop" style="padding: 5px 12px 0 12px"> 
      RANK
       <v-btn icon color="white"  @click="sareLink" ><v-icon> mdi-24px mdi-share</v-icon></v-btn>
      <v-spacer></v-spacer>
     <h4 v-if="$store.state.userData"> {{ rank }}위 / {{itemsNum}}명</h4>
      <Avataaars :width="50" :height="50" :avatarOptions="$store.state.userData.options" v-if="$store.state.userData" />
    </v-card-title>
    <!-- 랭크테이블 -->
    <v-card-text>
 
      <v-simple-table dark>
        <thead>
          <tr >
            <th class="text-center"  >순위</th>
            <th class="text-right"></th>
            <th class="text-center">이름</th>
            <th class="text-center">소속</th>
            <th class="text-center" v-if="maxAdd==='max'">기록</th>
            <th class="text-center" v-else>기록</th>
            <th class="text-center">도전</th>
          </tr>
        </thead>
        <tbody>
          <tr  v-for="(a,i) in items"  :key="i" >
            <td class="text-center">{{ a.rank }}</td> 
            <td class="text-center" style="width: 30px;padding:0;margin: 0;" > <Avataaars :width="35" :height="35" :avatarOptions="a.options" />    </td> 
            <td class="text-center " style="width: 70px;padding:0;margin: 0;" > <span> {{ a.name }}</span> </td>              
            <td class="text-center " style="width: 100px;padding:0;margin: 0;" > <span> {{ a.team }}</span> </td>              
            <td class="text-center">{{ maxAdd==='max'?a.record:a.recordSum }}</td>
            <td class="text-center">{{ a.recordRepeat }}</td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import Avataaars from "vue-avataaars";
export default {
  name: "DialogRank",
  props: ['allItem','items','rank',"id",'maxAdd','itemsNum','rankSahre'],
  components: {  Avataaars },
  data() {
    return {
      headers: [
        { value: "rank", text: "순위", align: "center" },
        { value: "name", text: "이름", align: "center", sortable: false }, 
        { value: "record", text: "최고기록", align: "center", sortable: false }, 
      ],
    };
  },

  created() {
    console.log("item",this.items,this.rankSahre);
  },
  destroyed() {
  },
  methods: { 
    sareLink(){
    window.open(this.rankSahre, '_blank');

    }
  },
};
</script>

<style lang="scss" scoped>
v-data-table {
  flex-direction: row;
}
.rankTop {
  background-color: #272727;
  border-bottom: 3px solid var(--second-color);
}
.cardRank {
  overflow: auto;
  background-color: #595959;

  box-shadow: 0 25px 45px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
</style>
